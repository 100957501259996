<template>
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0091 7.45622L14.6758 2.78955M14.6758 2.78955H10.6758M14.6758 2.78955V6.78955M7.34245 10.1229L2.67578 14.7896M2.67578 14.7896H6.67578M2.67578 14.7896L2.67578 10.7896" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconExpanded'
}
</script>
